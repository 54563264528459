/* HTML code

<div class="nei-accordion" id="accordion-id-1">
  <div class="nei-accordion__container">
    <h5 data-toggle-accordion="accordion-id-1">Title</h5>
    <div class="nei-accordion__content">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec semper pretium sagittis. Nullam vehicula commodo, lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec semper pretium sagittis.
      </p>
    </div>
  </div>
</div>

*/

function NeiAccordion (el, clickEvent, toggleDataAttr, selectorEl, containerSelectorEl, callbackStartAnimFunc, callbackEndAnimFunc, closeOtherTabsFunc, openId, scrollToSection, onlyOpens, extraHeight) {
  this.el = el;
  this.clickEvent = clickEvent ? clickEvent : false;
  this.toggleDataAttr = toggleDataAttr ? toggleDataAttr : 'data-toggle-accordion';
  this.selectorEl = selectorEl ? selectorEl : '.nei-accordion';
  this.containerSelectorEl = containerSelectorEl ? containerSelectorEl : '.nei-accordion__container';
  this.callbackStartAnimFunc = callbackStartAnimFunc;
  this.callbackEndAnimFunc = callbackEndAnimFunc;
  this.closeOtherTabsFunc = closeOtherTabsFunc;
  this.openId = openId;
  this.scrollToSection = scrollToSection;
  this.onlyOpens = onlyOpens;
  this.extraHeight = extraHeight || function() { return 0; };

  this.toggleIsOutsideContainer = openId ? true : false;

  this.toggleEl = this.toggleIsOutsideContainer ? document.querySelector('['+ this.toggleDataAttr + '="'+ this.openId +'"]') : this.el.querySelector('['+ this.toggleDataAttr + ']');
  this.containerEl = this.el.querySelector(this.containerSelectorEl);
  this.contentEl = this.containerEl.querySelector('.nei-accordion__content');
  this.contentEl.style.display = 'block';

  this.openId = this.openId ? this.openId : this.toggleEl.getAttribute(this.toggleDataAttr);
  this.el = document.querySelector('#'+ this.openId);

  this.initHeight = this.toggleIsOutsideContainer ? 0 : this.toggleEl.getBoundingClientRect().height;//if you define the openId is because the toggle is outside the container, so the container height is zero
  this.heightContainer = this.extraHeight() && MdcLayoutHelper.isMobileView() ? this.toggleEl.getBoundingClientRect().height + this.contentEl.getBoundingClientRect().height + this.extraHeight() : this.toggleEl.getBoundingClientRect().height + this.contentEl.getBoundingClientRect().height;

  this.animTiming = 800 * this.heightContainer / 2000; // 800ms to 2000px of height/

  if(this.animTiming > 1500) {//if anim time is more than 1500ms stays 1500ms
    this.animTiming = 1500;
  } else if(this.animTiming < 800) { //if anim time is less than 1000ms stays 1000ms
    this.animTiming = 800;
  }

  this.states = undefined;
  this.animations = {
    openSelectedTab : {
      params: undefined,
      anim: undefined
    },
    closeSelectedTab : {
      params: undefined,
      anim: undefined
    }
  };

  this._init();
}



/*
 * INIT
 */
NeiAccordion.prototype._init = function () {
  this._initStates();
  this._initAnimations();

  //init height
  this.el.style.maxHeight = this.initHeight +'px';

  this.toggleEl.addEventListener('click', function(event) {
    if(this.onlyOpens && this.states.state == 'closed')
      event.preventDefault();

    if(this.clickEvent) {
      if(this.onlyOpens && this.states.state == 'closed' || !this.onlyOpens)
        this.clickHandler();
    }

  }.bind(this) );

  debouncedResizeHandler = debounce(this.updateHeight.bind(this), 200);
  window.addEventListener('resize', debouncedResizeHandler);
};


/*
 * Update Height
 */
NeiAccordion.prototype.updateHeight = function () {
  this.initHeight = this.toggleIsOutsideContainer ? 0 : this.toggleEl.getBoundingClientRect().height;

  var tempHeightContainer = this.extraHeight() && MdcLayoutHelper.isMobileView() ? this.toggleEl.getBoundingClientRect().height + this.contentEl.getBoundingClientRect().height + this.extraHeight() : this.toggleEl.getBoundingClientRect().height + this.contentEl.getBoundingClientRect().height;
  this.heightContainer = this.containerEl.offsetHeight > this.heightContainer ?  tempHeightContainer : this.heightContainer;

  this.animTiming = 800 * this.heightContainer / 2000; // 800ms to 2000px of height/

  if(this.animTiming > 1500) {//if anim time is more than 1500ms stays 1500ms
    this.animTiming = 1500;
  } else if(this.animTiming < 800) { //if anim time is less than 800ms stays 800ms
    this.animTiming = 800;
  }

  this._resetAnimations();

  if(this.states.state == 'closed') {
    this.el.style.maxHeight = this.initHeight +'px';
  }
};



/*
 * Init states
 */
NeiAccordion.prototype._initStates = function() {
  this.states = new StateMachine({
    init: 'closed',
    transitions: [
      { name: 'open',  from: 'closed', to: 'open'   },
      { name: 'close', from: 'open',   to: 'closed' }
    ]
  });
};

/*
 * Init animations
 */
NeiAccordion.prototype._initAnimations = function() {
  this.animations.openSelectedTab.params = {
    targets: this.el,
    maxHeight: [this.initHeight+'px', this.heightContainer+'px'],
    duration: this.animTiming,
    easing: 'easeInOutQuad',
    begin: function(anim) {

      if (this.callbackStartAnimFunc) {
        this.callbackStartAnimFunc(true);
      }
      if(this.states.state == 'closed') {

        //* Open Tab
        this.el.classList.add('tab--active');
        this.el.classList.add('tab--animate');
        this.toggleEl.classList.add('toggle--active');

        this.states.open();

        //* Close the other tabs
        if(this.closeOtherTabsFunc)
          this.closeOtherTabsFunc();
      }
    }.bind(this),
    complete: function(anim) {
      if (this.callbackEndAnimFunc) {
        this.callbackEndAnimFunc();
      }

      if(this.scrollToSection && MdcLayoutHelper.isMobileView()) {
        if(this.states.state == 'open') {
          var y = $(window).scrollTop();
          var scrollToPost = y + this.el.getBoundingClientRect().top - document.querySelector('header').getBoundingClientRect().top - document.querySelector('header').getBoundingClientRect().height - document.querySelector('[data-toggle-accordion]').getBoundingClientRect().height ;

          if(!isInHeightViewport( this.el, 60, window.innerHeight/4)) {
            $('html, body').animate({scrollTop: scrollToPost }, this.animTiming);
          }
        }
      }

      this.el.style.maxHeight = 'initial';
    }.bind(this)
  };

  this.animations.closeSelectedTab.params = {
    targets: this.el,
    maxHeight: [this.heightContainer+'px', this.initHeight+'px'],
    duration: this.animTiming,
    easing: 'easeInOutQuad',
    begin: function(anim) {

      if (this.callbackStartAnimFunc) {
        this.callbackStartAnimFunc();
      }
      if(this.states.state == 'open') {
        this.el.style.maxHeight = this.heightContainer;

        //* Close tab
        this.el.classList.remove('tab--active');
        this.toggleEl.classList.remove('toggle--active');

        this.states.close();
      }
    }.bind(this),
    complete: function(anim) {
      this.el.classList.remove('tab--animate');
      if (this.callbackEndAnimFunc) {
        this.callbackEndAnimFunc();
      }
    }.bind(this)
  };
};


/*
 * Reset animations
 */
NeiAccordion.prototype._resetAnimations = function() {
  this.animations = {
    openSelectedTab : {
      params: undefined,
      anim: undefined
    },
    closeSelectedTab : {
      params: undefined,
      anim: undefined
    }
  };

  this._initAnimations();
};


/*
 * Click Handler
 */
NeiAccordion.prototype.clickHandler = function () {
  //update state based on .tab--active
  if(this.states.state == 'open') {
    this.close();
  }
  else if(this.states.state == 'closed') {
    this.open();
  }
};

/*
 * Open
 */
NeiAccordion.prototype.open = function() {
  this._initAnimation('openSelectedTab');

  if(this.states.state == 'closed') {
    this.animations.openSelectedTab.anime.direction = 'normal';
    this.animations.openSelectedTab.anime.play();
  }
};

/*
 * Close
 */
NeiAccordion.prototype.close = function() {
  this._initAnimation('closeSelectedTab');

  if(this.states.state == 'open') {
    this.animations.closeSelectedTab.anime.direction = 'normal';
    this.animations.closeSelectedTab.anime.play();
  }
};


/*
 * Init animation
 */
NeiAccordion.prototype._initAnimation = function(name) {
  if(!this.animations[name].anime)
    this.animations[name].anime = anime(this.animations[name].params);
};
